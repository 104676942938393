.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 25px;
  font-weight: bold;
}

.form {
  border: 1px solid var(--color-gray-400);
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
}
