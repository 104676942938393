:root {
  --color-red-50: #ffebee;
  --color-red-100: #ffcdd2;
  --color-red-200: #ef9a9a;
  --color-red-300: #e57373;
  --color-red-400: #ef5350;
  --color-red-500: #f44336;
  --color-red-600: #e53935;
  --color-red-700: #d32f2f;
  --color-red-800: #c62828;
  --color-red-900: #b71c1c;
  --color-red-A100: #ff8a80;
  --color-red-A200: #ff5252;
  --color-red-A400: #ff1744;
  --color-red-A700: #d50000;
  --color-pink-50: #fce4ec;
  --color-pink-100: #f8bbd0;
  --color-pink-200: #f48fb1;
  --color-pink-300: #f06292;
  --color-pink-400: #ec407a;
  --color-pink-500: #e91e63;
  --color-pink-600: #d81b60;
  --color-pink-700: #c2185b;
  --color-pink-800: #ad1457;
  --color-pink-900: #880e4f;
  --color-pink-A100: #ff80ab;
  --color-pink-A200: #ff4081;
  --color-pink-A400: #f50057;
  --color-pink-A700: #c51162;
  --color-purple-50: #f3e5f5;
  --color-purple-100: #e1bee7;
  --color-purple-200: #ce93d8;
  --color-purple-300: #ba68c8;
  --color-purple-400: #ab47bc;
  --color-purple-500: #9c27b0;
  --color-purple-600: #8e24aa;
  --color-purple-700: #7b1fa2;
  --color-purple-800: #6a1b9a;
  --color-purple-900: #4a148c;
  --color-purple-A100: #ea80fc;
  --color-purple-A200: #e040fb;
  --color-purple-A400: #d500f9;
  --color-purple-A700: #a0f;
  --color-deep-purple-50: #ede7f6;
  --color-deep-purple-100: #d1c4e9;
  --color-deep-purple-200: #b39ddb;
  --color-deep-purple-300: #9575cd;
  --color-deep-purple-400: #7e57c2;
  --color-deep-purple-500: #673ab7;
  --color-deep-purple-600: #5e35b1;
  --color-deep-purple-700: #512da8;
  --color-deep-purple-800: #4527a0;
  --color-deep-purple-900: #311b92;
  --color-deep-purple-A100: #b388ff;
  --color-deep-purple-A200: #7c4dff;
  --color-deep-purple-A400: #651fff;
  --color-deep-purple-A700: #6200ea;
  --color-indigo-50: #e8eaf6;
  --color-indigo-100: #c5cae9;
  --color-indigo-200: #9fa8da;
  --color-indigo-300: #7986cb;
  --color-indigo-400: #5c6bc0;
  --color-indigo-500: #3f51b5;
  --color-indigo-600: #3949ab;
  --color-indigo-700: #303f9f;
  --color-indigo-800: #283593;
  --color-indigo-900: #1a237e;
  --color-indigo-A100: #8c9eff;
  --color-indigo-A200: #536dfe;
  --color-indigo-A400: #3d5afe;
  --color-indigo-A700: #304ffe;
  --color-blue-50: #e3f2fd;
  --color-blue-100: #bbdefb;
  --color-blue-200: #90caf9;
  --color-blue-300: #64b5f6;
  --color-blue-400: #42a5f5;
  --color-blue-500: #2196f3;
  --color-blue-600: #1e88e5;
  --color-blue-700: #1976d2;
  --color-blue-800: #1565c0;
  --color-blue-900: #0d47a1;
  --color-blue-A100: #82b1ff;
  --color-blue-A200: #448aff;
  --color-blue-A400: #2979ff;
  --color-blue-A700: #2962ff;
  --color-light-blue-50: #e1f5fe;
  --color-light-blue-100: #b3e5fc;
  --color-light-blue-200: #81d4fa;
  --color-light-blue-300: #4fc3f7;
  --color-light-blue-400: #29b6f6;
  --color-light-blue-500: #03a9f4;
  --color-light-blue-600: #039be5;
  --color-light-blue-700: #0288d1;
  --color-light-blue-800: #0277bd;
  --color-light-blue-900: #01579b;
  --color-light-blue-A100: #80d8ff;
  --color-light-blue-A200: #40c4ff;
  --color-light-blue-A400: #00b0ff;
  --color-light-blue-A700: #0091ea;
  --color-cyan-50: #e0f7fa;
  --color-cyan-100: #b2ebf2;
  --color-cyan-200: #80deea;
  --color-cyan-300: #4dd0e1;
  --color-cyan-400: #26c6da;
  --color-cyan-500: #00bcd4;
  --color-cyan-600: #00acc1;
  --color-cyan-700: #0097a7;
  --color-cyan-800: #00838f;
  --color-cyan-900: #006064;
  --color-cyan-A100: #84ffff;
  --color-cyan-A200: #18ffff;
  --color-cyan-A400: #00e5ff;
  --color-cyan-A700: #00b8d4;
  --color-teal-50: #e0f2f1;
  --color-teal-100: #b2dfdb;
  --color-teal-200: #80cbc4;
  --color-teal-300: #4db6ac;
  --color-teal-400: #26a69a;
  --color-teal-500: #009688;
  --color-teal-600: #00897b;
  --color-teal-700: #00796b;
  --color-teal-800: #00695c;
  --color-teal-900: #004d40;
  --color-teal-A100: #a7ffeb;
  --color-teal-A200: #64ffda;
  --color-teal-A400: #1de9b6;
  --color-teal-A700: #00bfa5;
  --color-green-50: #e8f5e9;
  --color-green-100: #c8e6c9;
  --color-green-200: #a5d6a7;
  --color-green-300: #81c784;
  --color-green-400: #66bb6a;
  --color-green-500: #4caf50;
  --color-green-600: #43a047;
  --color-green-700: #388e3c;
  --color-green-800: #2e7d32;
  --color-green-900: #1b5e20;
  --color-green-A100: #b9f6ca;
  --color-green-A200: #69f0ae;
  --color-green-A400: #00e676;
  --color-green-A700: #00c853;
  --color-light-green-50: #f1f8e9;
  --color-light-green-100: #dcedc8;
  --color-light-green-200: #c5e1a5;
  --color-light-green-300: #aed581;
  --color-light-green-400: #9ccc65;
  --color-light-green-500: #8bc34a;
  --color-light-green-600: #7cb342;
  --color-light-green-700: #689f38;
  --color-light-green-800: #558b2f;
  --color-light-green-900: #33691e;
  --color-light-green-A100: #ccff90;
  --color-light-green-A200: #b2ff59;
  --color-light-green-A400: #76ff03;
  --color-light-green-A700: #64dd17;
  --color-lime-50: #f9fbe7;
  --color-lime-100: #f0f4c3;
  --color-lime-200: #e6ee9c;
  --color-lime-300: #dce775;
  --color-lime-400: #d4e157;
  --color-lime-500: #cddc39;
  --color-lime-600: #c0ca33;
  --color-lime-700: #afb42b;
  --color-lime-800: #9e9d24;
  --color-lime-900: #827717;
  --color-lime-A100: #f4ff81;
  --color-lime-A200: #eeff41;
  --color-lime-A400: #c6ff00;
  --color-lime-A700: #aeea00;
  --color-yellow-50: #fffde7;
  --color-yellow-100: #fff9c4;
  --color-yellow-200: #fff59d;
  --color-yellow-300: #fff176;
  --color-yellow-400: #ffee58;
  --color-yellow-500: #ffeb3b;
  --color-yellow-600: #fdd835;
  --color-yellow-700: #fbc02d;
  --color-yellow-800: #f9a825;
  --color-yellow-900: #f57f17;
  --color-yellow-A100: #ffff8d;
  --color-yellow-A200: #ff0;
  --color-yellow-A400: #ffea00;
  --color-yellow-A700: #ffd600;
  --color-amber-50: #fff8e1;
  --color-amber-100: #ffecb3;
  --color-amber-200: #ffe082;
  --color-amber-300: #ffd54f;
  --color-amber-400: #ffca28;
  --color-amber-500: #ffc107;
  --color-amber-600: #ffb300;
  --color-amber-700: #ffa000;
  --color-amber-800: #ff8f00;
  --color-amber-900: #ff6f00;
  --color-amber-A100: #ffe57f;
  --color-amber-A200: #ffd740;
  --color-amber-A400: #ffc400;
  --color-amber-A700: #ffab00;
  --color-orange-50: #fff3e0;
  --color-orange-100: #ffe0b2;
  --color-orange-200: #ffcc80;
  --color-orange-300: #ffb74d;
  --color-orange-400: #ffa726;
  --color-orange-500: #ff9800;
  --color-orange-600: #fb8c00;
  --color-orange-700: #f57c00;
  --color-orange-800: #ef6c00;
  --color-orange-900: #e65100;
  --color-orange-A100: #ffd180;
  --color-orange-A200: #ffab40;
  --color-orange-A400: #ff9100;
  --color-orange-A700: #ff6d00;
  --color-deep-orange-50: #fbe9e7;
  --color-deep-orange-100: #ffccbc;
  --color-deep-orange-200: #ffab91;
  --color-deep-orange-300: #ff8a65;
  --color-deep-orange-400: #ff7043;
  --color-deep-orange-500: #ff5722;
  --color-deep-orange-600: #f4511e;
  --color-deep-orange-700: #e64a19;
  --color-deep-orange-800: #d84315;
  --color-deep-orange-900: #bf360c;
  --color-deep-orange-A100: #ff9e80;
  --color-deep-orange-A200: #ff6e40;
  --color-deep-orange-A400: #ff3d00;
  --color-deep-orange-A700: #dd2c00;
  --color-brown-50: #efebe9;
  --color-brown-100: #d7ccc8;
  --color-brown-200: #bcaaa4;
  --color-brown-300: #a1887f;
  --color-brown-400: #8d6e63;
  --color-brown-500: #795548;
  --color-brown-600: #6d4c41;
  --color-brown-700: #5d4037;
  --color-brown-800: #4e342e;
  --color-brown-900: #3e2723;
  --color-gray-50: #fafafa;
  --color-gray-100: #f5f5f5;
  --color-gray-200: #eee;
  --color-gray-300: #e0e0e0;
  --color-gray-400: #bdbdbd;
  --color-gray-500: #9e9e9e;
  --color-gray-600: #757575;
  --color-gray-700: #616161;
  --color-gray-800: #424242;
  --color-gray-900: #212121;
  --color-blue-gray-50: #eceff1;
  --color-blue-gray-100: #cfd8dc;
  --color-blue-gray-200: #b0bec5;
  --color-blue-gray-300: #90a4ae;
  --color-blue-gray-400: #78909c;
  --color-blue-gray-500: #607d8b;
  --color-blue-gray-600: #546e7a;
  --color-blue-gray-700: #455a64;
  --color-blue-gray-800: #37474f;
  --color-blue-gray-900: #263238;
  --color-black: #000;
  --color-white: #fff;
}

.y2lPIa_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.y2lPIa_title {
  font-size: 25px;
  font-weight: bold;
}

.y2lPIa_form {
  border: 1px solid var(--color-gray-400);
  border-radius: 10px;
  margin-top: 20px;
  padding: 30px;
}

.rY3X-q_sform {
  padding: 20px;
}

.rY3X-q_grid2 {
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  display: grid;
}

.rY3X-q_grid4 {
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.rY3X-q_grid41 {
  grid-auto-columns: 3fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.rY3X-q_grid3 {
  grid-auto-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.rY3X-q_grid31 {
  grid-auto-columns: 2fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.rY3X-q_grid1 {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: grid;
}

@media only screen and (width <= 600px) {
  .rY3X-q_sform {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rY3X-q_grid2, .rY3X-q_grid4, .rY3X-q_grid41, .rY3X-q_grid3, .rY3X-q_grid31, .rY3X-q_grid1 {
    flex-direction: column;
    width: 100%;
    display: flex;
  }
}

.rY3X-q_center {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.rY3X-q_flexJustify {
  background-color: var(--color-red-900);
  color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.R5AVPa_body {
  height: 67vh;
  overflow-y: auto;
}

.R5AVPa_titlestep {
  font-weight: 500;
}

@media only screen and (width <= 600px) {
  .R5AVPa_body {
    height: 50vh;
    overflow-y: auto;
  }
}

.BPvLkW_title {
  font-size: 25px;
  font-weight: 500;
}

.BPvLkW_garisbawah {
  border-bottom: 1px solid var(--color-gray-500);
  padding: 5px 0;
}

.BPvLkW_name {
  font-size: 15px;
  font-weight: 500;
}

.BPvLkW_address, .BPvLkW_city, .BPvLkW_company {
  display: none;
}

@media only screen and (width <= 600px) {
  .BPvLkW_address, .BPvLkW_city, .BPvLkW_company {
    display: block;
  }
}

.BPvLkW_center {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.nColBG_container {
  flex-direction: row;
  align-items: center;
  height: 100%;
  display: flex;
}

.nColBG_left, .nColBG_right {
  flex-direction: row;
  justify-content: center;
  width: 50%;
  display: flex;
}

.nColBG_box {
  text-align: center;
}

.nColBG_h1 {
  color: #1d5d9b;
  font-size: 30px;
  font-weight: 700;
}

.nColBG_button span {
  padding-left: 15px;
  font-weight: 600;
  position: relative;
}

.nColBG_button span svg {
  position: absolute;
  top: -20px;
  left: 0;
}

@media only screen and (width <= 600px) {
  .nColBG_container {
    flex-direction: column;
    height: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
  }

  .nColBG_left, .nColBG_right {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 60px;
    display: flex;
  }
}

._9-2fHW_container {
  flex-direction: row;
  align-items: center;
  height: 100%;
  display: flex;
}

._9-2fHW_left, ._9-2fHW_right {
  flex-direction: row;
  justify-content: center;
  width: 50%;
  display: flex;
}

._9-2fHW_box {
  text-align: center;
}

._9-2fHW_h1 {
  color: #1d5d9b;
  font-size: 30px;
  font-weight: 700;
}

._9-2fHW_button span {
  padding-left: 15px;
  font-weight: 600;
  position: relative;
}

._9-2fHW_button span svg {
  position: absolute;
  top: -20px;
  left: 0;
}

@media only screen and (width <= 600px) {
  ._9-2fHW_container {
    flex-direction: column;
    height: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
  }

  ._9-2fHW_left, ._9-2fHW_right {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 60px;
    display: flex;
  }
}

.kVnkCa_sform {
  padding: 20px;
}

.kVnkCa_grid2 {
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  display: grid;
}

.kVnkCa_grid4 {
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.kVnkCa_grid41 {
  grid-auto-columns: 3fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.kVnkCa_grid3 {
  grid-auto-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.kVnkCa_grid31 {
  grid-auto-columns: 2fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.kVnkCa_grid1 {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: grid;
}

@media only screen and (width <= 600px) {
  .kVnkCa_sform {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .kVnkCa_grid2, .kVnkCa_grid4, .kVnkCa_grid41, .kVnkCa_grid3, .kVnkCa_grid31, .kVnkCa_grid1 {
    flex-direction: column;
    width: 100%;
    display: flex;
  }
}

.kVnkCa_body {
  height: 82vh;
  overflow-y: auto;
}

@media only screen and (width <= 600px) {
  .kVnkCa_body {
    height: 82vh;
    overflow-y: auto;
  }
}

.kVnkCa_tenagaTeknisTitle {
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
  display: flex;
}

.kVnkCa_center {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.hrfX0q_sform {
  padding: 20px;
}

.hrfX0q_grid2 {
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  display: grid;
}

.hrfX0q_grid4 {
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.hrfX0q_grid41 {
  grid-auto-columns: 3fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.hrfX0q_grid3 {
  grid-auto-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.hrfX0q_grid31 {
  grid-auto-columns: 2fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.hrfX0q_grid1 {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: grid;
}

@media only screen and (width <= 600px) {
  .hrfX0q_sform {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .hrfX0q_grid2, .hrfX0q_grid4, .hrfX0q_grid41, .hrfX0q_grid3, .hrfX0q_grid31, .hrfX0q_grid1 {
    flex-direction: column;
    width: 100%;
    display: flex;
  }
}

.hrfX0q_body {
  height: 82vh;
  overflow-y: auto;
}

@media only screen and (width <= 600px) {
  .hrfX0q_body {
    height: 82vh;
    overflow-y: auto;
  }
}

.hrfX0q_tenagaTeknisTitle {
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
  display: flex;
}

.hrfX0q_center {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.b01dSq_sform {
  padding: 20px;
}

.b01dSq_grid2 {
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  display: grid;
}

.b01dSq_grid4 {
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.b01dSq_grid41 {
  grid-auto-columns: 3fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.b01dSq_grid3 {
  grid-auto-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.b01dSq_grid31 {
  grid-auto-columns: 2fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.b01dSq_grid1 {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: grid;
}

@media only screen and (width <= 600px) {
  .b01dSq_sform {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .b01dSq_grid2, .b01dSq_grid4, .b01dSq_grid41, .b01dSq_grid3, .b01dSq_grid31, .b01dSq_grid1 {
    flex-direction: column;
    width: 100%;
    display: flex;
  }
}

.b01dSq_body {
  height: 82vh;
  overflow-y: auto;
}

@media only screen and (width <= 600px) {
  .b01dSq_body {
    height: 82vh;
    overflow-y: auto;
  }
}

.b01dSq_tenagaTeknisTitle {
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
  display: flex;
}

.b01dSq_center {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.b01dSq_flexJustify {
  background-color: var(--color-red-900);
  color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.layout {
  width: 100%;
  position: fixed;
}

.content {
  padding: 0 50px;
}

@media only screen and (width <= 600px) {
  .content {
    padding: 0 15px;
  }
}

.ci6zOW_sform {
  padding: 20px;
}

.ci6zOW_grid2 {
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  display: grid;
}

.ci6zOW_grid4 {
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.ci6zOW_grid41 {
  grid-auto-columns: 3fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.ci6zOW_grid3 {
  grid-auto-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.ci6zOW_grid31 {
  grid-auto-columns: 2fr 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  display: grid;
}

.ci6zOW_grid1 {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: grid;
}

@media only screen and (width <= 600px) {
  .ci6zOW_sform {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ci6zOW_grid2, .ci6zOW_grid4, .ci6zOW_grid41, .ci6zOW_grid3, .ci6zOW_grid31, .ci6zOW_grid1 {
    flex-direction: column;
    width: 100%;
    display: flex;
  }
}

.ci6zOW_body {
  height: 82vh;
  overflow-y: auto;
}

@media only screen and (width <= 600px) {
  .ci6zOW_body {
    height: 82vh;
    overflow-y: auto;
  }
}

.ci6zOW_tenagaTeknisTitle {
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
  display: flex;
}

.ci6zOW_center {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.ci6zOW_flexJustify {
  background-color: var(--color-red-900);
  color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

/*# sourceMappingURL=index.c8930ff8.css.map */
