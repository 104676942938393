.title {
  font-size: 25px;
  font-weight: 500;
}
.garisbawah {
  padding: 5px 0px;
  border-bottom: 1px solid var(--color-gray-500);
}
.name {
  font-size: 15px;
  font-weight: 500;
}
.address {
  display: none;
}
.city {
  display: none;
}
.company {
  display: none;
}
@media only screen and (max-width: 600px) {
  .address {
    display: block;
  }
  .city {
    display: block;
  }
  .company {
    display: block;
  }
}
.center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
