.layout {
  position: fixed;
  width: 100%;
}
.content {
  padding: 0 50px;
}
@media only screen and (max-width: 600px) {
  .content {
    padding: 0 15px;
  }
}
